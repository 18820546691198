import { useState, useEffect } from "react";

// Custom hook para monitorar o localStorage
function useLocalStorageItem(key) {
  const [storedValue, setStoredValue] = useState(localStorage.getItem(key));

  useEffect(() => {
    const handleStorageChange = () => {
      const newValue = localStorage.getItem(key);
      setStoredValue(newValue);
    };

    // Interval para monitorar mudanças no próprio componente
    const interval = setInterval(handleStorageChange, 100);

    // Cleanup
    return () => clearInterval(interval);
  }, [key]);

  return storedValue;
}

export default useLocalStorageItem;
